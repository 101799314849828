import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { NotificationProvider } from 'utility/notification';

// Libraries
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';

// Internationalization
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { messages as enMessages } from './locales/en/messages';
import { messages as frMessages } from './locales/fr/messages';
import { en, fr } from 'make-plural/plurals'

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('fr', { plurals: fr })

i18n.load({
    en: enMessages,
    fr: frMessages,
});


// Initialize language to the browser default
let defaultLang = navigator.language.toLocaleLowerCase();

// Check if user set their own language in localStorage
const lang = localStorage.getItem('store_lang');

if (['en', 'fr'].includes(lang)) {
    defaultLang = lang;
}


if (defaultLang.includes('fr')) {
    i18n.activate('fr');
} else {
    i18n.activate('en');
}


// Initialize Stripe from js.stripe.com/v3/ script tag
const stripePromise = loadStripe(process.env.REACT_APP_PUB_STRIPE_KEY, { apiVersion: '2023-10-16' });


// Extend dayjs with plugins
dayjs.extend(utc)
dayjs.extend(timezone)



function Root() {
    return (
        <Elements stripe={stripePromise}>
            <I18nProvider i18n={i18n}>
                <NotificationProvider>
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <ThemeProvider theme={theme}>

                            <BrowserRouter>

                                <div className='content'>
                                    <App />
                                </div>

                                <Footer />

                            </BrowserRouter>

                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </NotificationProvider>
            </I18nProvider>
        </Elements>
    )
}

function Footer() {
    return (
        <footer className='footer'>

            <div className='container'>

                <div className='column'>
                    <h2 className='header'><Trans>Support</Trans></h2>
                    <a className='support-link' href='https://frontdoor.plus/?page_id=175'><Trans>Ticket Support</Trans></a>
                    <a className='link' href='https://frontdoor.plus/Privacy-Policy.html'><Trans>Privacy Policy</Trans></a>
                    <a className='link' href='https://frontdoor.plus/Terms-and-Conditions.html'><Trans>Terms of Service</Trans></a>
                </div>

                <div className='column'>
                    <h2 className='header'><Trans>Follow Us</Trans></h2>
                    <a className='link' href='https://www.instagram.com/frontdoor.plus'>Instagram</a>
                    <a className='link' href='https://www.facebook.com/FrontDoor.Plus'>Facebook</a>
                    <a className='link' href='https://www.linkedin.com/company/b4tsolutions/'>LinkedIn</a>
                </div>

            </div>
            
            <p className='app-version'>{process.env.REACT_APP_VERSION}</p>
        </footer>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
