import React, { useState, useEffect, useRef } from 'react';

// Material-UI
import { styled, Box, Hidden, Typography, Button, Paper, Stepper as _Stepper, Step, StepLabel as _StepLabel, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import OrderSummary from 'components/OrderSummary';
import CustomerForm from 'components/CustomerForm';
import PaymentForm from 'components/PaymentForm';
import Registration from 'registration/Registration';

// Utility
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import * as isValid from 'utility/validation';
import { postUserAndPayment, getDiscountByCode } from 'utility/requests';
import { getRegistrationQuestions } from 'registration/RegistrationAPI'
import { isRegistrationRequired, initializeAttendee, formatRegistrationFormForPurchase } from 'registration/RegistrationUtils';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import * as GA4 from 'analytics/ga4';
import * as MetaPixel from 'analytics/metaPixel';


export default function Checkout(props) {
    const { products, bundles, event, paidTransaction, showDiscountForm, discount, discountFormState, discountDispatch, onDiscountChange, accessCodes, onPurchase, onCancel } = props;

    const { i18n } = useLingui();
    const stripe = useStripe();
    const elements = useElements();


    // State
    const [status, setStatus] = useState('Loading'); // status = Loading | Error | Registration | Checkout | Submit
    const [errorMessage, setErrorMessage] = useState('');

    const contentRef = useRef();

    
    // Registration State
    const [registrationEnabled, setRegistrationEnabled] = useState(event.checkoutQuestionMode === 1 || event.checkoutQuestionMode === 2);
    const [registrationForm, setRegistrationForm] = useState(null);


    // Initialize State
    useEffect(() => {
        async function init() {
            if (registrationEnabled) {
                const questions = await getRegistrationQuestions(event.eventId);

                const registrationRequired = isRegistrationRequired(event.checkoutQuestionMode, questions, products, bundles);

                if (registrationRequired) {
                    const attendeeForm = initializeAttendee(event.checkoutQuestionMode, products, bundles, questions);
                    
                    setRegistrationEnabled(true);
                    setRegistrationForm(attendeeForm);
                    setStatus('Registration');
                } else {
                    setRegistrationEnabled(false);
                    setStatus('Checkout');
                }

            }
            // Registration is disabled. Skip to payment.
            else {
                setRegistrationEnabled(false);
                setStatus('Checkout');
            }
        }
        init().catch(e => {
            setRegistrationEnabled(false);
            setStatus('Error');
        })
        // eslint-disable-next-line
    }, []);
    
    // Resets the scroll position of the browser before rendering the registration or payment forms
    useEffect(() => {
        if (status === 'Registration' || status === 'Checkout') {
            contentRef.current.scrollIntoView({ block: 'end' });
        }
    }, [status]);


    // Customer Contact Form
    const [customerForm, setCustomerForm] = useState(() => initializeForm(event));

    // Backup form values to localStorage every time the state changes
    useEffect(() => {
        sessionStorage.setItem(process.env.REACT_APP_CHECKOUT_KEY, JSON.stringify({
            eventId: event.eventId,
            lastUpdated: Date.now(),
            form: Object.keys(customerForm).reduce((p, n) => {
                p[n] = customerForm[n].value;
                return p;
            }, {})
        }))
    }, [customerForm, event.eventId]);

    // Credit Card Form
    const [paymentForm, setPaymentForm] = useState({
        ccNumber: { error: false },
        cvc: { error: false },
        ccExpiry: { error: false },
    })


    const handleRegistrationSubmit = () => {
        setStatus('Checkout');
    };


    const handleFormChange = (field, value) => {
        let newState = { ...customerForm };

        switch(field) {
            case 'country':
                newState = {...customerForm, [field]: { ...customerForm[field], value}, province: {...customerForm.province, value: ''}}
                break;
            
            case 'postalCode':
                let postal = value.replace(/[^0-9a-zA-Z]/g,'').slice(0, 6).toUpperCase();
                newState = {...customerForm, [field]: { ...customerForm[field], value: postal}}
                break;

            case 'phone':
                let phone = value.replace(/[^0-9]/g,'').slice(0,11); // We changed the max length from 10 to 11 because if people enter +1 at the start, the last number will get cut off
                newState = {...customerForm, [field]: { ...customerForm[field], value: phone}}
                break;

            case 'email':
            case 'emailConfirm':
                let email = value.replace(/\s/g,'');
                newState = {...customerForm, [field]: { ...customerForm[field], value: email}}
                break;
                
            default:
                newState = {...customerForm, [field]: { ...customerForm[field], value}}
        }

        setCustomerForm(newState);
    };

    const handleAddressAutocomplete = (address, city, province, country, postal) => {
        setCustomerForm(s => ({
            ...s,
            address: { value: address, error: false, message: '' },
            city: { value: city, error: false, message: '' },
            province: { value: province, error: false, message: '' },
            country: { value: country, error: false, message: '' },
            postalCode: { value: postal, error: false, message: '' },
        }));

        // TODO: We need to backup the latest form changes to localStorage.
        //       We can do this here, or take the backup logic from handleFormChange() and extract it to an effect that runs on every render
    };

    async function handleFormSubmit() {

        if (isFormValid() === false) {
            setErrorMessage(t`Please review required fields`);

            GA4.exception('Payment Error: missing fields')

            return null;
        }
        setStatus('Submit');

        // Form is valid. Processing payment
        try {
            let tokenId = null;

            // Create stripe token if user buys a paid product
            if (paidTransaction){
                // Create token
                const cardNumberElement = elements.getElement(CardNumberElement);
                const { token, error } = await stripe.createToken(cardNumberElement, { name: customerForm.ccName.value });

                // Return if credit card fields are invalid
                if (error) {
                    switch(error.code) {
                        case 'incomplete_number':
                            setErrorMessage(t`Please review required fields`);
                            setPaymentForm({ccNumber: { error: true }, cvc: { error: false }, ccExpiry: { error: false }})
                            break;
                        
                        case 'incomplete_cvc':
                            setErrorMessage(t`Please review required fields`);
                            setPaymentForm({ccNumber: { error: false }, cvc: { error: true }, ccExpiry: { error: false }})
                            break;
                        
                        case 'incomplete_expiry':
                            setErrorMessage(t`Please review required fields`);
                            setPaymentForm({ccNumber: { error: false }, cvc: { error: false }, ccExpiry: { error: true }})
                            break;
    
                        case 'card_declined':
                            setErrorMessage(t`Your payment was declined`);
                            break;
    
                        default:
                            setErrorMessage(t`Payment failed. Please try again.`);
                    }
    
                    setStatus('Checkout');
                    return null;
                } else {
                    tokenId = token.id;
                    setPaymentForm({ccNumber: { error: false }, cvc: { error: false }, ccExpiry: { error: false }})
                }
            }

            const transactionData = {
                locale: i18n.locale,
		    	event: { eventId: event.eventId },
		    	customer: {
		    		country: customerForm.country.value,
		    		region: customerForm.province.value,
		    		email: customerForm.email.value,
		    		postal: customerForm.postalCode.value,
		    		city: customerForm.city.value,
		    		tel: customerForm.phone.value,
		    		fn: customerForm.firstName.value,
                    ln: customerForm.lastName.value,
		    		sa: customerForm.address.value,
		    		sourceApp: 'Storefront'
                },
                products: products.filter(p => p.qty > 0).map(p => ({ id: p.id, quantity: p.qty, price: p.price })),
                bundles: bundles.filter(p => p.qty > 0).map(p => ({ id: p.id, quantity: p.qty, price: p.price })),
                stripe: {
                    source: tokenId,
                    description: event.title,
                    currency: 'CAD'
                },
                discountCode: null,
                attendees: formatRegistrationFormForPurchase(registrationForm),
                accessCodes: accessCodes
            }

            if (discountFormState.status === 'success') {
                transactionData.discountCode = discount.code;
            }

            postUserAndPayment(transactionData)
                .then(data => {
                    if (data.paid === true) {
                        onPurchase(customerForm.email.value, discount, data.ticketCount, data.tran_id, `${data.appleWalletURL}&lang=${i18n.locale}`, `${data.googleWalletURL}&lang=${i18n.locale}`);
                        
                        try {
                            const { id, total, tax } = data.transaction;
                            const discountCode = discount?.code;

                            // Log purchase in google analytics and meta pixel
                            GA4.purchase(id, total, tax, discountCode);

                            if (event.metaPixelId) {
                                MetaPixel.purchase(event.metaPixelId, total);
                            }
    
                            sessionStorage.removeItem(process.env.REACT_APP_REGISTRATION_KEY);
                            sessionStorage.removeItem(process.env.REACT_APP_CHECKOUT_KEY);
                        }
                        catch(e) {
                            // Don't interrupt the purchase flow, since the order already went through by this point
                            return null;
                        }
                    }
                    else {
                        const errors = {
                            // Stripe Errors
                            incorrect_number: t`The card number entered is incorrect.`,
                            invalid_number: t`The card number entered is not a valid credit card number.`,
                            invalid_expiry_month: t`Your card's expiration month is invalid.`,
                            invalid_expiry_year: t`Your card's expiration year is invalid.`,
                            invalid_cvc: t`Your card's security code is invalid.`,
                            expired_card: t`Your card has expired.`,
                            incorrect_cvc: t`Your card's security code is incorrect.`,
                            incorrect_zip: t`Your card's zip code failed validation.`,
                            card_declined: t`Your card was declined.`,
                            missing: t`There is no card on a customer that is being charged.`,
                            processing_error: t`An error occurred while processing your credit card.`,
                            // FrontDoor+ Errors
                            bad_request: t`Please ensure your contact and payment information are correct.`,
                            sold_out: t`An item in your cart is sold out.`,
                            not_enough_stock: t`There are not enough tickets in stock to fulfill this order.`,
                            ticket_not_available: t`An item in your cart is not available for sale.`,
                            invalid_discount: t`The discount code entered is invalid.`,
                            discount_limit_reached: t`The discount code entered has expired.`,
                            discount_expired: t`The discount code entered has expired.`,
                            discount_customer_limit_reached: t`You have reached your limit for this discount code. Please remove the code and try again.`,
                            invalid_event: t`We are having issues accepting payments right now. Please try again later.`,
                            // Generic Error
                            default_error: t`Unable to process your payment.`
                        };

                        const errorMessage = errors[data.code] || errors.default_error;

                        setErrorMessage(errorMessage);

                        setStatus('Checkout');

                        GA4.exception(`Payment Error: ${errorMessage} - ${event.title}`);
                    }
                })
                .catch(() => {
                    try {
                        setErrorMessage(t`There was a problem making your purchase`);

                        setStatus('Checkout');

                        GA4.exception(`Error - ${event.title}`, true);
                        
                    } catch(e) {
                        return null;
                    }
                })
        }
        catch(err) {
            setErrorMessage(t`Something went wrong. Your purchase could not be completed.`);
            setStatus('Checkout');
        }
    }

    function isFormValid() {
        const { firstName, lastName, email, emailConfirm, address, city, country, province, postalCode, ccName, phone } = customerForm;
        let newState = {}

        newState.firstName = isValid.name(firstName.value)
            ? { ...firstName, error: false, message: '' }
            : { ...firstName, error: true, message: t`Please enter your first name` }
        
        newState.lastName = isValid.name(lastName.value)
            ? { ...lastName, error: false, message: '' }
            : { ...lastName, error: true, message: t`Please enter your last name` }

        newState.email = isValid.email(email.value)
            ? { ...email, error: false, message: '' }
            : { ...email, error: true, message: t`Please enter a valid email address` }

        newState.emailConfirm = isValid.email(emailConfirm.value)
            ? { ...emailConfirm, error: false, message: '' }
            : { ...emailConfirm, error: true, message: t`Please enter a valid email address` }

        if (email.value.toLowerCase() !== emailConfirm.value.toLowerCase()) {
            newState.email = { ...email, error: true, message: t`The emails provided do not match`}
            newState.emailConfirm = { ...emailConfirm, error: true, message: t`The emails provided do not match`}
        }

        newState.address = isValid.address(address.value)
            ? { ...address, error: false, message: '' }
            : { ...address, error: true, message: t`Please enter your address` }
        
        newState.city = isValid.city(city.value)
            ? { ...city, error: false, message: '' }
            : { ...city, error: true, message: t`Please enter your city` }

        const POSTAL_ERROR = {
            'US': t`Please enter a valid ZIP code`,
            'CA': t`Please enter a valid postal code`,
            'GB': t`Please enter a valid postcode`,
            'AU': t`Please enter a valid postcode`,
        }
        newState.postalCode = isValid.postalCode(postalCode.value, country.value)
            ? { ...postalCode, error: false, message: '' }
            : { ...postalCode, error: true, message: POSTAL_ERROR[country.value] }

        if (country.value === 'GB' || country.value === 'AU') {
            // We don't ask for province if user selects a region other than US/Canada
            newState.province = { ...province, error: false, message: '' }
        } else {
            newState.province = isValid.select(province.value)
                ? { ...province, error: false, message: '' }
                : { ...province, error: true, message: country.value === 'CA' ? t`Please select a Province` : t`Please select a State` }
        }

        // Validate ccName only if user buying paid products
        if (paidTransaction) {
            newState.ccName = isValid.name(ccName.value)
                ? { ...ccName, error: false, message: '' }
                : { ...ccName, error: true, message: t`Please enter your name` }
        }

        newState.phone = isValid.phone(phone.value)
            ? { ...phone, error: false, message: '' }
            : { ...phone, error: true, message: t`Please enter a valid phone number` }
 
        setCustomerForm({...customerForm, ...newState});

        // Return true if form is valid
        return !Object.keys(newState).some((key) => newState[key].error);
    }


    const handleDiscountSubmit = () => {
        if (discountFormState.status === 'loading') return;
        discountDispatch({ type: 'setStatus', status: 'loading' });

        getDiscountByCode(discountFormState.code, event.eventId)
            .then(discount => {
                switch (discount.status) {
                    case 'DISCOUNT_CODE_VALID':
                        discountDispatch({ type: 'setStatus', status: 'success', message: t`Discount applied` });
                        onDiscountChange(discount);
                        break;
                    case 'DISCOUNT_CODE_NOT_FOUND':
                        discountDispatch({ type: 'setStatus', status: 'error', message: t`Not a valid code` });
                        break;
                    case 'DISCOUNT_CODE_EXPIRED':
                    case 'DISCOUNT_CODE_EXHAUSTED':
                        discountDispatch({ type: 'setStatus', status: 'error', message: t`This code is expired` });
                        break;
                    default:
                        discountDispatch({ type: 'setStatus', status: 'error', message: t`Unable to verify code` });
                        break;
                }
            })
            .catch(() => {
                discountDispatch({ type: 'setStatus', status: 'error', message: t`Unable to verify code` })
            })
    }

    const handleDiscountChange = (value) => {
        discountDispatch({ type: 'setCode', code: value })
    }

    const handleDiscountDelete = () => {
        discountDispatch({ type: 'deleteCode' });
        onDiscountChange(null);
    };

    const disablePurchase = (status === 'Submit');


    return (
        <Box flex={1}>
            <Box ref={contentRef} />

            {status === 'Loading' && (
                <Container>
                    <CircularProgress />
                </Container>
            )}

            {status === 'Error' && (
                <Container>
                    <Typography><Trans>Unable to proceed with checkout. Please close this window and try again.</Trans></Typography>
                </Container>
            )}

            {(['Registration','Checkout','Submit'].includes(status) && registrationEnabled === true) && (
                <Header>
                    {status === 'Checkout' && (
                        <Box width='80px'>
                            <Button startIcon={<ArrowBackIcon />} onClick={() => setStatus('Registration')}>Back</Button>
                        </Box>
                    )}
                    <Box flexGrow={1}>
                        <Stepper activeStep={status === 'Registration' ? 0 : 1} alternativeLabel>
                            <Step key={1}>
                                <StepLabel><Trans>Form</Trans></StepLabel>
                            </Step>
                            <Step key={2}>
                                <StepLabel><Trans>Payment</Trans></StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                </Header>
            )}

            {(status === 'Registration' && registrationForm !== null) && (
                <Registration
                    form={registrationForm}
                    mode={event.checkoutQuestionMode}
                    onFormChange={setRegistrationForm}
                    onSubmit={handleRegistrationSubmit}
                    onCancel={onCancel}
                />
            )}

            {(status === 'Checkout' || status === 'Submit') && (
                <Box pb={'270px'}>
                    <Root>
                        <Box width={[1,1, 60/100]} p={2}>
                            <CustomerForm
                                onFormChange={handleFormChange}
                                onAddressAutocomplete={handleAddressAutocomplete}
                                values={customerForm}
                            />
                            {
                                paidTransaction && <PaymentForm onFormChange={handleFormChange} values={customerForm} ccState={paymentForm}/>
                            }
                            <Hidden mdUp>
                                <OrderSummary
                                    products={products}
                                    bundles={bundles}
                                    event={event}
                                    paidTransaction={paidTransaction}
                                    discount={discount}
                                    discountFormState={discountFormState}
                                    showDiscountForm={showDiscountForm}
                                    onDiscountChange={handleDiscountChange}
                                    onDiscountSubmit={handleDiscountSubmit}
                                    onDiscountDelete={handleDiscountDelete}
                                    onClick={handleFormSubmit}
                                    disablePurchase={disablePurchase}
                                    errorMessage={errorMessage}
                                />
                            </Hidden>
                        </Box>
                        <Hidden smDown>
                            <Box width={40/100} p={2}>
                                <OrderSummary
                                    products={products}
                                    bundles={bundles}
                                    event={event}
                                    paidTransaction={paidTransaction}
                                    discount={discount}
                                    discountFormState={discountFormState}
                                    showDiscountForm={showDiscountForm}
                                    onDiscountChange={handleDiscountChange}
                                    onDiscountSubmit={handleDiscountSubmit}
                                    onDiscountDelete={handleDiscountDelete}
                                    onClick={handleFormSubmit}
                                    disablePurchase={disablePurchase}
                                    errorMessage={errorMessage}
                                />
                            </Box>
                        </Hidden>
                    </Root>
                    <Root>
                        <Box my={4} mx={2}>
                            <Typography variant='caption'>{event.finePrint || ''}</Typography>
                        </Box>
                    </Root>
                </Box>
            )}
        </Box>
    )
}


function initializeForm(event) {

    const defaultState = {
        firstName: { value: '', error: false, message: '' },
        lastName: { value: '', error: false, message: '' },
        email: { value: '', error: false, message: '' },
        emailConfirm: { value: '', error: false, message: '' },
        address: { value: '', error: false, message: '' },
        city: { value: '', error: false, message: '' },
        province: { value: event.country === 'Canada' ? event.province : '', error: false, message: '' },
        country: { value: 'CA', error: false, message: '' },
        postalCode: { value: '', error: false, message: '' },
        ccName: { value: '', error: false, message: '' },
        phone: { value: '', error: false, message: '' }
    }

    const sessionData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_CHECKOUT_KEY));

    if (sessionData) {
        if (sessionData.eventId === event.eventId && (Date.now() - sessionData.lastUpdated) < (1 * 60 * 60 * 1000)) {
            // Restore user checkout session
            Object.keys(defaultState).forEach((p) => {
                defaultState[p].value = sessionData.form[p];
            })
        } else {
            // Clear invalid checkout form data
            sessionStorage.removeItem(process.env.REACT_APP_CHECKOUT_KEY);
        }
    }

    return defaultState;
};


const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        width: theme.breakpoints.values.sm,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md,
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}));

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 360,
    justifyContent: 'center',
    alignItems: 'center'
}));

const Header = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: 0
}));

const Stepper = styled(_Stepper)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
}));

const StepLabel = styled(_StepLabel)(({ theme }) => ({
    "& .MuiStepLabel-label" : {
        margin: 0
    }
}));