
export const formatPrice = (num) => {
	return '$' + Number(num).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const convertToCents = (num) => {
	return Number((num * 100).toFixed(0));
}

export const convertToDollars = (num) => {
	return Number((num / 100).toFixed(2));
}
