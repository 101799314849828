import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useParams, Link } from "react-router-dom";

// Pages
import Storefront from 'components/Storefront';

// Utilities
import { getEventById, getProductsByEventId } from 'utility/requests';
import { generateProductCart, generateBundleCart } from 'utility/cart';
import { Trans } from '@lingui/macro';
import { setStructuredData } from 'structuredData';
import { setPageMetadata } from 'pageMetadata';
import * as MetaPixel from 'analytics/metaPixel';
import dayjs from 'dayjs';



function EventPage() {
    // status: Loading | Error | Success
    const [state, setState] = useState({
        event: null,
        products: null,
        bundles: null,
        accessCodes: [],
        accessCodesEnabled: false,
        status: 'Loading',
        storefrontKey: 0, // Increment to render a new instance of the Storefront component
    })


    const params = useParams();

    const eventId = params.eventId.replace(/[^0-9]/g,'');


    // Reset scroll position to top after component renders
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);


    // Get Event info and products
    useEffect(() => { 
        if (eventId) {

            Promise.all([getEventById(eventId), getProductsByEventId(eventId)])
                .then(results => {

                    const event = results[0];

                    // This event has a meta pixel
                    // Initialize, and log first page view
                    if (event.metaPixelId) {
                        MetaPixel.initialize(event.metaPixelId);
                        MetaPixel.viewContent(event.metaPixelId, 'Event Page');
                    }

                    // Create "Carts" for bundles and products
                    // Only includes items currently for sale
                    const products = generateProductCart(results[1].products, event.timezone);
                    const bundles = generateBundleCart(results[1].bundles, event.timezone);

                    setState(s => ({
                        ...s,
                        event: event,
                        products: products.cart,
                        bundles: bundles.cart,
                        accessCodes: [],
                        accessCodesEnabled: products.accessCodeEnabled || bundles.accessCodeEnabled,
                        status: 'Success',
                        storefrontKey: s.storefrontKey + 1
                    }))

                    // Set Google Structured Data for event
                    setStructuredData(event);

                    // Set <meta> tags in the <head> of the document with event information
                    setPageMetadata({
                        title: `${event.title} Tickets`,
                        description: `Experience ${event.title} by ${event.hostName} - ${dayjs(event.startDate).format('dddd, D MMMM YYYY')} at ${event.venue}`,
                        image: event.imageUrl
                    })

                })
                .catch(() => {
                    setState(s => ({
                        ...s,
                        event: null, 
                        product: null,
                        bundles: null,
                        accessCodes: null,
                        status: 'Error'
                    }));
                })
        }
        else {
            setState(s => ({
                ...s,
                event: null, 
                products: null,
                bundles: null,
                accessCodes: null,
                status: 'Error'
            }));
        }
    // eslint-disable-next-line
    }, [eventId])


    const handleAccessCodeSubmit = async (code) => {
        try {
            const codes = [...state.accessCodes, code];

            // Fetch products again, but with the latest access codes
            const data = await getProductsByEventId(eventId, { codes });

            const products = generateProductCart(data.products, state.event.timezone);
            const bundles = generateBundleCart(data.bundles, state.event.timezone);
    
            // Re-mount the <Storefront/> component. This will reset the cart, and show the latest product list (including those unlocked via access codes)
            setState(s => ({
                ...s,
                products: products.cart,
                bundles: bundles.cart,
                status: 'Success',
                accessCodes: codes,
                accessCodesEnabled: products.accessCodeEnabled || bundles.accessCodeEnabled,
                storefrontKey: s.storefrontKey + 1
            }))
        } catch(e) {
            // TODO: Show a toast message explaining that the code could not be applied
            console.log(e)
        }
    };


    switch (state.status) {
        
        case 'Success':
        return (
            <Storefront
                key={state.storefrontKey}
                event={state.event}
                products={state.products}
                bundles={state.bundles}
                accessCodes={state.accessCodes}
                accessCodesEnabled={state.accessCodesEnabled}
                onAccessCodeSubmit={handleAccessCodeSubmit}
            />
        )
        
        case 'Loading':
        return <LoadingIcon />

        case 'Error':
        return <ErrorScreen />

        default:
        return <ErrorScreen />
    }
}

function LoadingIcon() {
    return (
        <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
            <CircularProgress />
        </Box>
    )
}

function ErrorScreen() {
    return (
        <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center'>
            <Typography style={{marginBottom: 24}} variant='h5'><Trans>Sorry we couldn't find that event</Trans></Typography>
            <Link style={{textDecoration: 'none'}} to='/'>
                <Button variant='outlined'><Trans>View all events</Trans></Button>
            </Link>
        </Box>
    )
}

export default EventPage;
