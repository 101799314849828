import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { styled, Box, Button, CircularProgress, TextField, Paper, Typography } from '@material-ui/core';

// Components
import OrderReceipt from 'components/OrderReceipt';

// Utility
import { Trans, t } from '@lingui/macro';


export default function OrderSummary(props) {
    const { products, bundles, event, paidTransaction, discount, discountFormState, showDiscountForm, onClick, onDiscountSubmit, onDiscountChange, onDiscountDelete, disablePurchase, errorMessage } = props;

    const c = useStyles();


    return (
        <div>
            <h3><Trans>Order Summary</Trans></h3>

            {showDiscountForm && (
                <DiscountForm>
                    <TextField
                        size='small'
                        variant='outlined'
                        color='secondary'
                        fullWidth
                        label={t`Discount Code`}
                        value={discountFormState.code}
                        onChange={(e) => onDiscountChange(e.target.value)}
                        error={discountFormState.status === 'error'}
                        helperText={discountFormState.message}
                        InputProps={discountFormState.status === 'success' ? {classes:{root: c.inputRoot}, readOnly: true} : undefined}
                        InputLabelProps={discountFormState.status === 'success' ? {classes:{root: c.inputLabelRoot}} : undefined}
                        FormHelperTextProps={discountFormState.status === 'success' ? {classes:{root: c.formHelperTextRoot}} : undefined}
                    />
                    {discountFormState.status === 'success' ? (
                        <DiscRemoveButton size='small' variant='outlined' onClick={onDiscountDelete}><Trans>Remove</Trans></DiscRemoveButton>
                    ) : (<>
                        {(discountFormState.status === 'loading' || discountFormState.code.length === 0)
                            ? <DiscDisabledButton size='small' variant='outlined' disabled><Trans>Apply</Trans></DiscDisabledButton>
                            : <DiscApplyButton size='small' color='primary' variant='contained' onClick={onDiscountSubmit}><Trans>Apply</Trans></DiscApplyButton>
                        }
                    </>)}
                </DiscountForm>
            )}

            <OrderReceipt
                products={products}
                bundles={bundles}
                event={event}
                paidTransaction={paidTransaction}
                discount={discount}
            />
                    
            <Box textAlign='center' mt='1rem' mb='1rem'><Trans>Please note: All purchases are final, and refunds are not available.</Trans></Box>

            {
                errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
            }
            <Box display='flex' justifyContent='center' width={1} mt='1rem' mb='1rem'>
                <PurchaseButton variant='contained' onClick={onClick} disabled={disablePurchase}>
                    {
                        disablePurchase ? (<PurchaseSpinner size={24} />) : t`Complete Your Order`
                    }
                </PurchaseButton>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    inputRoot: {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      }
    },
    inputLabelRoot: {
        color: theme.palette.success.main,
    },
    formHelperTextRoot: {
        color: theme.palette.success.main,
    },
  }));

const DiscountForm = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: theme.spacing(9),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
}));

const DiscApplyButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    marginLeft: theme.spacing(1)
}));
const DiscDisabledButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    marginLeft: theme.spacing(1)
}));
const DiscRemoveButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1)
}));

const PurchaseButton = styled(Button)(({ theme }) => ({
    width: '75%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

const PurchaseSpinner = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    textAlign: 'center'
}));