export function getRegistrationQuestions(eventId) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/events/${eventId}/questions`)
            .then(async res => {
                if (res.status !== 200) throw new Error();
                const data = res.json();
                resolve(data);
            })
            .catch((e) => {
                reject(e)
            })
    })
}