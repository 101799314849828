import React from 'react';

// Material UI
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell, TableContainer, Paper } from '@material-ui/core';

// Components
import InfoTooltip from 'components/InfoTooltip';

// Utility
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { calculateOrder } from 'calculations';


export default function OrderReceipt(props) {
    const { products, bundles, event, paidTransaction, discount } = props;

    const cart = bundles.concat(products).map(i => ({
        name: i.name,
        price: i.price,
        qty: i.qty,
        paidProductCount: i.paidProductCount
    }));


    const fees = calculateOrder(cart, products, event, discount, paidTransaction);

    const { subTotal, discountTotal, serviceFee, creditCardFee, tax, total, unifiedFee } = fees;
    const { taxName } = event.fees;


    const { i18n } = useLingui();
    const c = useStyles();

    const formatCurrency = (value) => i18n.number(value, { style: 'currency', currency: 'CAD' });


    return (
        <TableContainer component={Paper} className={c.root}>
            <div className={c.table}>
                <Table>
                    <TableBody>
                        {
                            cart.map((product, index) => {
                                if (product.qty > 0) {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={c.listCell}>{`${product.name} x${product.qty}`}</TableCell>
                                            <TableCell className={c.listCell} align='right'>{formatCurrency(product.price * product.qty)}</TableCell>
                                        </TableRow>
                                    )
                                } else { return null }
                            })
                        }
                        {
                            (subTotal && ((event.taxable && !event.taxIncluded) || !event.hostAbsorbsB4tFees || !event.hostAbsorbsCcFees)) ? (
                                <TableRow>
                                    <TableCell className={c.subTotalCell}><b><Trans>Sub Total</Trans></b></TableCell>
                                    <TableCell className={c.subTotalCell} align='right'><b>{formatCurrency(subTotal)}</b></TableCell>
                                </TableRow>
                            ) : null
                        }
                    </TableBody>
                </Table>

                <Table>
                    <TableBody>
                        {
                            (discount) ? (
                                <TableRow>
                                    <TableCell className={c.discountListCell}>
                                        <Typography><Trans>Discount</Trans></Typography>
                                        {typeof fees.discountsApplied === 'number' && (
                                            <Typography variant='caption'>({
                                                fees.discountsApplied === 1
                                                    ? t`on ${fees.discountsApplied} ticket`
                                                    : t`on ${fees.discountsApplied} tickets`
                                            })</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell className={c.discountListCell} align='right'>- {formatCurrency(discountTotal)}</TableCell>
                                </TableRow>
                            ) : null
                        }
                        {
                            (subTotal && event.taxable && !event.taxIncluded) ? (
                                <TableRow>
                                    <TableCell className={c.listCell}>{taxName}</TableCell>
                                    <TableCell className={c.listCell} align='right'>{formatCurrency(tax)}</TableCell>
                                </TableRow>
                            ) : null
                        }


                        {event.province === 'QC' ? (
                            <>
                                {
                                    (subTotal && (!event.hostAbsorbsB4tFees || !event.hostAbsorbsCcFees)) ? (
                                        <TableRow>
                                            <TableCell className={c.listCell}>
                                                <Box display='flex' alignItems='center'>
                                                    <Box mr='0.35rem'><Trans>FD+ Fee</Trans></Box>
                                                    <InfoTooltip message={t`This fee not only goes towards your FrontDoor+ experience but also keeps our lights on, making your ticket-buying adventures even better!`} />
                                                </Box>
                                            </TableCell>
                                            <TableCell className={c.listCell} align='right'>{formatCurrency(unifiedFee)}</TableCell>
                                        </TableRow>
                                    ) : null
                                }
                             </>
                        ) : (
                            <>
                                {
                                    (subTotal && !event.hostAbsorbsB4tFees) ? (
                                        <TableRow>
                                            <TableCell className={c.listCell}>
                                                <Box display='flex' alignItems='center'>
                                                    <Box mr='0.35rem'><Trans>Convenience Fee</Trans></Box>
                                                    <InfoTooltip message={t`This fee not only goes towards your FrontDoor+ experience but also keeps our lights on, making your ticket-buying adventures even better!`} />
                                                </Box>
                                            </TableCell>
                                            <TableCell className={c.listCell} align='right'>{formatCurrency(serviceFee)}</TableCell>
                                        </TableRow>
                                    ) : null
                                }
                                {
                                    (subTotal && !event.hostAbsorbsCcFees) ? (
                                        <TableRow>
                                            <TableCell className={c.listCell}>
                                                <Box display='flex' alignItems='center'>
                                                    <Box mr='0.35rem'><Trans>Credit Card Fee</Trans></Box>
                                                    <InfoTooltip message={t`The payment processing bank folks need a little something for their hard work in making your transactions smooth and secure.`} />
                                                </Box>
                                            </TableCell>
                                            <TableCell className={c.listCell} align='right'>{formatCurrency(creditCardFee)}</TableCell>
                                        </TableRow>
                                    ) : null
                                }
                            </>
                        )}

                        <TableRow>
                            <TableCell className={c.totalCell}><b><Trans>Total</Trans></b></TableCell>
                            <TableCell className={c.totalCell} align='right'><b>{formatCurrency(total)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: 24,
        marginBottom: 24,
        borderRadius: theme.spacing(2)
    },
    table: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    listCell: {
        border: 'none',
        paddingTop: 0
    },
    discountListCell: {
        border: 'none',
        paddingTop: 0,
        color: theme.palette.success.dark,
        verticalAlign: 'top'
    },
    subTotalCell: {
        border: 'none',
        borderTop: '1px solid',
        paddingBottom: theme.spacing(4)
    },
    totalCell: {
        border: 'none',
        borderTop: '1px solid',
        paddingBottom: 0
    },
}))